<template>
  <div class="bg-white rounded">
    <div class="border border-bottom-0 p-3">
      <div
        class="d-flex justify-content-between"
      >
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            {{ fioTitle }}
          </div>
          <div class="break-word pre-line">
            {{ fullName || '—' }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Номер телефона
          </div>
          <div class="break-word pre-line">
            {{ phoneNumber }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Статус
          </div>
          <div :style="{color: appealStatusColor}">
            {{ appealStatus || '—' }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Цель обращения
          </div>
          <div class="break-word pre-line">
            {{ purposeTitle }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Дата и время
          </div>
          <div class="break-word pre-line mb-2">
            {{ appealDate }}
          </div>
          <div class="font-weight-bold mb-2">
            Дата закрытия
          </div>
          <div class="break-word pre-line">
            {{ appealDateClose }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            ID обращения
          </div>
          <div class="break-word pre-line">
            {{ appeal.id }}
          </div>
        </div>

        <div class="ml-3 btn-block">
          <b-button
            v-b-tooltip.hover.left
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="p-1"
            title="Редактировать"
            @click="openAppealEditorModal"
          >
            <b-icon icon="pencil-square" />
          </b-button>

          <b-button
            v-b-tooltip.hover.left
            variant="success"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="mt-2 p-1"
            title="История изменений"
            @click="openAppealHistoryModal"
          >
            <b-icon icon="book" />
          </b-button>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-3 pb-1 mt-2 mb-2 border-top">
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Категория обращения
          </div>
          <div class="break-word pre-line">
            {{ category }}
          </div>
        </div>
        <div
          v-if="appeal.categoryAnotherText"
          class="mr-3 w-100"
        >
          <div class="font-weight-bold mb-2">
            Другая категория обращения
          </div>
          <div class="break-word pre-line">
            {{ appeal.categoryAnotherText }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Тип обращения
          </div>
          <div class="break-word pre-line">
            {{ appeal.type?.name || '—' }}
          </div>
        </div>
        <div
          v-if="appeal.typeAnotherText"
          class="mr-3 w-100"
        >
          <div class="font-weight-bold mb-2">
            Другой тип обращения
          </div>
          <div class="break-word pre-line">
            {{ appeal.typeAnotherText }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Срочно
          </div>
          <div :class="{ 'text-danger': appeal.urgency }">
            {{ appeal.urgency ? 'Срочно' : 'Не срочно' }}
          </div>
        </div>
        <div class="mr-3 w-100">
          <div class="font-weight-bold mb-2">
            Нужна ли транспортировка
          </div>
          <div :class="{ 'text-danger': appeal.transportation }">
            {{ appeal.transportation ? 'Нужна' : 'Не нужна' }}
          </div>
        </div>
        <div
          v-if="!appeal.categoryAnotherText"
          class="mr-3 w-100"
        />
        <div
          v-if="!appeal.typeAnotherText"
          class="mr-3 w-100"
        />

        <div class="ml-3 btn-block invisible user-select-none">
          <b-button
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="p-1"
          >
            <b-icon icon="x" />
          </b-button>
        </div>
      </div>
      <template v-if="isInfoShow">
        <div class="d-flex justify-content-between border-top pt-3 pb-3 mb-3 border-bottom">
          <div class="mr-3 w-100">
            <div class="font-weight-bold mb-2">
              Дата рождения
            </div>
            <div class="break-word pre-line">
              {{ birthDateFormated }}
            </div>
          </div>
          <div class="mr-3 w-100">
            <div class="font-weight-bold mb-2">
              Возраст
            </div>
            <div class="break-word pre-line">
              {{ personAge }}
            </div>
          </div>
          <div class="mr-3 w-100">
            <div class="font-weight-bold mb-2">
              Город
            </div>
            <div class="break-word pre-line">
              {{ appeal.city?.name || '–' }}
            </div>
          </div>
          <div class="mr-3 w-100">
            <div class="font-weight-bold mb-2">
              Клиника
            </div>
            <div class="break-word pre-line">
              {{ appeal.clinic?.name || '–' }}
            </div>
          </div>
          <div class="mr-3 w-100">
            <div
              v-if="appeal.person?.type === 0"
              class="font-weight-bold mb-2"
            >
              Информация о пациенте
            </div>
            <div
              v-if="appeal.person?.type === 0"
              class="break-word pre-line"
            >
              {{ appeal.patientInfo || '–' }}
            </div>
          </div>
          <div class="mr-3 w-100" />
          <div
            class="ml-3 btn-block"
          >
            <b-button
              v-if="appeal.person?.type === 0"
              v-b-tooltip.hover.left
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="p-1"
              title="Детальная информация о пациенте"
              @click="openPatientDetail"
            >
              <b-icon icon="person-lines-fill" />
            </b-button>
          </div>
        </div>
        <div
          v-if="appeal.policy"
          class="w-100 pb-3 mb-3 border-bottom"
        >
          <!-- <policy-item
            :policy="appeal.policy"
            class="mr-3 w-100"
          /> -->
          <AppealPolicyItem
            :policy="appeal.policy"
            :person="appeal.person"
            class="mr-3 w-100"
          />
        </div>

        <template v-if="fields.length">
          <div
            v-for="(fieldsGroup, fieldsGroupIndex) in fields"
            :key="`fieldsGroup-${fieldsGroupIndex}`"
            class="d-flex justify-content-between pb-3 mb-3 border-bottom"
          >
            <div
              v-for="({ title, value }, fieldIndex) in fieldsGroup"
              :key="`field-${fieldsGroupIndex}-${fieldIndex}`"
              class="mr-3 w-100"
            >
              <div class="font-weight-bold mb-2">
                {{ title }}
              </div>
              <div
                :class="{'rejection-reason': title === 'Причина отказа'}"
                class="break-word pre-line"
              >
                {{ value }}
              </div>
            </div>

            <div class="ml-3 btn-block invisible user-select-none">
              <b-button
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="p-1"
              >
                <b-icon icon="x" />
              </b-button>
            </div>
          </div>
        </template>

        <div
          v-if="comment"
          class="pb-3 mb-3 border-bottom"
        >
          <div class="font-weight-bold mb-2">
            Комментарий
          </div>
          <div class="break-word pre-line">
            {{ comment }}
          </div>
        </div>

        <div
          v-if="appeal.reconciliationComment"
          class="pb-3 mb-3 border-bottom"
        >
          <div class="font-weight-bold mb-2">
            Комментарий ответственного
          </div>
          <div class="break-word pre-line">
            {{ appeal.reconciliationComment }}
          </div>
        </div>
        <div
          v-if="operatorComment || responsibleName"
          class="flex w-full flex-row"
        >
          <div
            v-if="operatorComment"
            class="pb-3 mb-3 border-bottom w-full"
          >
            <div class="font-weight-bold mb-2">
              Комментарий оператора
            </div>
            <div class="break-word pre-line">
              {{ operatorComment }}
            </div>
          </div>
          <div
            v-if="responsibleName"
            class="pb-3 mb-3 border-bottom w-full"
          >
            <div class="font-weight-bold mb-2">
              Ответственный
            </div>
            <div class="break-word pre-line">
              {{ responsibleName }}
            </div>
          </div>
        </div>
        <div
          v-if="appeal.files?.length"
          class="pb-3 mb-3 border-bottom"
        >
          <div class="font-weight-bold mb-2">
            Файлы
          </div>
          <div class="break-word pre-line">
            <div
              v-for="(file) in getComputedFiles(appeal.files)"
              :key="file.id"
              class="mt-2"
            >
              <base-file
                :file="file"
                class="cursor-pointer"
              />
            </div>
          </div>
        </div>

        <div
          v-for="reconciliation in appeal.reconciliations"
          :key="reconciliation.id"
        >
          <base-dropdown
            :open="false"
            header="Информация о согласовании"
          >
            <b-row class="mb-3">
              <b-col>
                <div class="font-weight-bold mb-2">
                  Статус
                </div>
                <div :class="getStatusColor(reconciliation?.status)">
                  {{ getStatusText(reconciliation.status) || '—' }}
                </div>
              </b-col>
              <b-col>
                <div class="font-weight-bold mb-2">
                  ID согласования
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.id || '—' }}
                </div>
              </b-col>
              <b-col>
                <div class="font-weight-bold mb-2">
                  Дата и время
                </div>
                <div class="break-word pre-line">
                  {{ getDate(reconciliation.createDate) || '—' }}
                </div>
              </b-col>
              <b-col>
                <div class="font-weight-bold mb-2">
                  ID связанного обращения
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.appealId || '—' }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col>
                <div class="font-weight-bold mb-2">
                  Согласованные услуги
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.agreedServices || '—' }}
                </div>
              </b-col>
              <b-col>
                <div class="font-weight-bold mb-2">
                  Название клиники
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.clinic?.name || '—' }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col>
                <div class="font-weight-bold mb-2">
                  Несогласованные услуги
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.notAgreedServices || '—' }}
                </div>
              </b-col>
              <b-col>
                <div class="font-weight-bold mb-2">
                  Причина отказа
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.regectionReason || '—' }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col>
                <div class="font-weight-bold mb-2">
                  Диагноз
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.diagnosis || '—' }}
                </div>
              </b-col>
              <b-col v-if="allowedEditDoctorFullName">
                <div class="font-weight-bold mb-2">
                  ФИО Врача
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.doctorName || '—' }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col>
                <div class="font-weight-bold mb-2">
                  Комментарий для оператора
                </div>
                <div class="break-word pre-line">
                  {{ appeal.operatorComment || '—' }}
                </div>
              </b-col>
              <b-col>
                <div class="font-weight-bold mb-2">
                  Комментарий врача-куратора
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.doctorComment || '—' }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="font-weight-bold mb-2">
                  Ответственный за согласование
                </div>
                <div class="break-word pre-line">
                  {{ reconciliation.responsible?.displayName || '—' }}
                </div>
              </b-col>
            </b-row>

            <div v-if="reconciliation.files?.length">
              <div class="mb-3">
                <div class="font-weight-bold mb-2">
                  Файлы
                </div>
                <div class="break-word pre-line">
                  <div
                    v-for="(file) in getComputedFiles(reconciliation.files)"
                    :key="file.id"
                    class="appeal-file"
                  >
                    <base-file
                      class="cursor-pointer"
                      :file="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </base-dropdown>
        </div>
      </template>
    </div>

    <div
      style="cursor: pointer;"
      class="bg-primary rounded-bottom d-flex justify-content-center p-2"
      @click="isInfoShow = !isInfoShow"
    >
      <b-icon
        icon="caret-down-fill"
        variant="light"
        :rotate="isInfoShow ? 180 : 0"
      />
    </div>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';
import AppealPolicyItem from '@/components/domains/appeals/AppealEditorModalNew/AppealPoliciesList/AppealPolicyItem';
import {
  BaseFile,
  BaseDropdown,
} from '@/components/base';

import {
  APPEALS_STATUSES_TEXTS,
  APPEALS_PURPOSES_TEXTS,
  APPEAL_RECONCILIATION_STATUSES_TEXTS,
  APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS,
} from '@/services/appeals/appeals.const';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

const FIELDS_COUNT_IN_GROUP = 6;

export default {
  name: 'AppealInfoNew',
  components: {
    BaseDropdown,
    BaseFile,
    AppealPolicyItem,
  },
  mixins: [mixinRoles],

  props: {
    appeal: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    statuses: {
      0: 'В работе',
      1: 'Отложено',
      2: 'Закрыто',
    }, // старые типы
    statusesAppeal: {
      0: 'В работе',
      1: 'Отложено',
      2: 'Закрыто',
    },
    purposes: {
      0: 'Информационное',
      1: 'Чекап',
      2: 'Лечение / плановая госпитализация',
      3: 'Экстренная госпитализация',
    }, // старые цели
    isInfoShow: false,
  }),
  computed: {
    fioTitle() {
      if (this.appeal?.person?.type === 0) return 'ФИО пациента';
      return 'ФИО врача';
    },
    allowedEditDoctorFullName() {
      return this.checkFeatureAccess({ name: 'ФИО врача в согл. в. инф. о пациенте', url: '/patients' });
    },
    isOldAppeal() {
      return this.appeal.isOldAppeal;
    },
    fullName() {
      if (!this.appeal.person) return '';
      const { lastName, firstName, middleName } = this.appeal.person;
      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    whereRrequestWasSentTo() {
      const { qualityControlPurposeType } = this.appeal;
      if (qualityControlPurposeType === null) return;
      return APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS.find((item) => item.id === qualityControlPurposeType)?.title;
    },
    phoneNumber() {
      return this.appeal.person?.phoneNumber || '—';
    },
    status() {
      return APPEALS_STATUSES_TEXTS.find((item) => item.id === this.appeal.status);
    },
    appealStatus() {
      if (this.isOldAppeal) {
        return this.statuses[this.appeal.status];
      }
      return this.status?.title;
    },
    appealStatusColor() {
      if (this.isOldAppeal) {
        switch (this.appeal.status) {
          case 0: return 'green';
          case 1: return 'orange';
          case 2: return 'red';
          default: return '';
        }
      }

      return this.status?.color;
    },
    purposeTitle() {
      if (this.appeal.isOldAppeal) {
        return this.purposes[this.appeal.oldPurpose];
      }
      return APPEALS_PURPOSES_TEXTS.find((item) => item.id === this.appeal.purpose)?.title;
    },
    appealDate() {
      return format(utc0ToDateWithUserTimezone(this.appeal.dateTime), 'dd.MM.yyyy HH:mm');
    },
    appealDateClose() {
      return this.appeal.closeDate !== null ? format(utc0ToDateWithUserTimezone(this.appeal.closeDate), 'dd.MM.yyyy HH:mm') : '-';
    },
    birthDateFormated() {
      return this.appeal.person?.birthDate ? format(parseISO(this.appeal.person.birthDate), 'dd.MM.yyyy') : '–';
    },
    category() {
      return this.appeal.category?.name || '–';
    },
    personAge() {
      if (!this.appeal.person?.birthDate) return '—';
      const date = new Date(this.appeal.person.birthDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateNow = new Date();
      const dayNow = dateNow.getDate();
      const monthNow = dateNow.getMonth() + 1;
      const yearNow = dateNow.getFullYear();
      let age = yearNow - year;

      if ((monthNow < month) || (monthNow === month && dayNow < day)) {
        age -= 1;
      }

      return age < 0 ? 0 : age;
    },
    checkupDate() {
      if (this.appeal.checkupDate) {
        return format(parseISO(this.appeal.checkupDate), 'dd.MM.yyyy');
      }

      return false;
    },
    policyBuyDate() {
      if (this.appeal.policyBuyDate) {
        return format(parseISO(this.appeal.policyBuyDate), 'dd.MM.yyyy');
      }

      return false;
    },
    selectedPurposeContractDepartament() {
      return this.purposeTitle === 'Договорной отдел';
    },
    selectedCategoryNoClient() {
      return this.category === 'Нет клиента в СРМ';
    },
    rejectionReason() {
      return this.appeal.rejectionReason;
    },
    checkupProfile() {
      return this.appeal.checkupProfile?.title;
    },
    anotherCheckupProfile() {
      if (this.appeal.checkupProfile && this.appeal.checkupProfile.title === 'Другое') {
        return this.appeal.checkupAnotherText;
      }

      return false;
    },
    isActivePolicy() {
      if (!this.selectedCategoryNoClient) return;

      return this.appeal.isActivePolicy ? 'Да' : 'Нет';
    },
    insuranseCompanyName() {
      return this.appeal.insuranseCompany?.name;
    },
    policyNumber() {
      return this.appeal.policyNumber;
    },
    administratorName() {
      return this.appeal.administratorName;
    },
    operatorComment() {
      return this.appeal.operatorComment;
    },
    yandexLink() {
      return this.appeal.yandexLink;
    },
    regionName() {
      return this.appeal.region?.name;
    },
    orderNumber() {
      return this.appeal.orderNumber;
    },
    consultationId() {
      return this.appeal.consultationId;
    },
    comment() {
      return this.appeal.comment;
    },
    responsibleName() {
      return this.appeal.responsible?.displayName;
    },
    responsibleOperatorName() {
      return this.appeal.responsibleOperator?.displayName;
    },

    fields() {
      let allFields = [
        {
          title: 'Причина отказа',
          value: this.rejectionReason,
        },
        {
          title: 'Профиль чекапа',
          value: this.checkupProfile,
        },
        {
          title: 'Другой профиль для чекапа',
          value: this.anotherCheckupProfile,
        },
        {
          title: 'Дата чекапа',
          value: this.checkupDate,
        },
        {
          title: 'Полис активирован на сайте страховой компании',
          value: this.isActivePolicy,
        },
        {
          title: 'Страховая компания',
          value: this.insuranseCompanyName,
        },
        {
          title: 'Номер полиса',
          value: this.policyNumber,
        },
        {
          title: 'ФИО Администратора',
          value: this.administratorName,
        },
        {
          title: 'Примерная дата приобретения полиса',
          value: this.policyBuyDate,
        },
        {
          title: 'Ссылка на яндекс трекер',
          value: this.yandexLink,
        },
        {
          title: 'Регион',
          value: this.regionName,
        },
        {
          title: 'Номер заказа',
          value: this.orderNumber,
        },
        {
          title: 'Id консультации',
          value: this.consultationId,
        },
        // {
        //   title: 'Комментарий',
        //   value: this.comment,
        // },
        {
          title: 'Куда передано обращение',
          value: this.whereRrequestWasSentTo,
        },
        {
          title: 'Оператор ответственный за обращение',
          value: this.responsibleOperatorName,
        },
      ];

      allFields = allFields.filter((item) => item.value);
      // eslint-disable-next-line array-callback-return
      const fieldsGroup = allFields.reduce((acc, item) => {
        // делим на массивы по 5 единиц
        console.log(acc, acc.length);
        if (acc[acc.length - 1].length === FIELDS_COUNT_IN_GROUP) {
          acc.push([]);
        }
        acc[acc.length - 1].push(item);
        return acc;
      }, [[]]);

      // что-бы не сломать сетку - добавляем пустые элементы
      const lastGroup = fieldsGroup[fieldsGroup.length - 1];
      if (lastGroup.length < FIELDS_COUNT_IN_GROUP) {
        while (lastGroup.length < FIELDS_COUNT_IN_GROUP) {
          lastGroup.push({
            title: '',
            value: '',
          });
        }
      }
      if (allFields.length > 0) return fieldsGroup;
      return [];
    },
  },

  methods: {
    getComputedFiles(fileIds) {
      return fileIds.map((file) => ({
        guid: file.id,
        title: file.name,
      }));
    },
    getStatusColor(status) {
      const isGreen = [1, 2, 3].includes(status);

      if (status === 0) return 'red';
      if (isGreen) return 'green';
      if (status === 4) return 'yellow';

      return '';
    },
    getStatusText(status, isAppeal) {
      return isAppeal ? this.statusesAppeal[status] : APPEAL_RECONCILIATION_STATUSES_TEXTS.find((item) => item.id === status)?.title;
    },
    getDate(dateTime) {
      return format(utc0ToDateWithUserTimezone(dateTime), 'dd.MM.yyyy HH:mm');
    },
    openAppealEditorModal() {
      if (this.appeal.person.type === 1) {
        uiService.showModal(MODALS.APPEAL_EDITOR_MODAL, {
          name: 'AppealEditorModal',
          props: {
            appealId: this.appeal.id,
            initDoctorData: {
              firstName: this.appeal.person?.firstName,
              lastName: this.appeal.person?.lastName,
              middleName: this.appeal.person?.middleName,
              phoneNumber: this.appeal.person?.phoneNumber,
              birthDate: this.appeal.person?.birthDate,
            },
            personId: this.appeal.person?.id,
            comment: this.appeal.comment,
            editMode: true,
            title: `Редактирование обращения - ${this.appeal.id}`,
          },
        });
        return;
      }
      uiService.showModal(MODALS.APPEAL_EDITOR_NEW_MODAL, {
        name: 'AppealEditorModalNew',
        props: {
          title: `Редактирование обращения - ${this.appeal.id}`,
          appealId: this.appeal.id,
          personId: this.appeal.person?.id,
          isEditAppeal: true,
        },
      });
    },
    openAppealHistoryModal() {
      uiService.showModal(MODALS.APPEAL_HISTORY_MODAL, {
        name: 'AppealHistoryModal',
        props: {
          appealId: this.appeal.id,
          personId: this.appeal.person?.id,
        },
      });
    },
    openPatientDetail() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDetailModal',
        props: {
          patientId: this.appeal.person.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-block{
  width: 27px;
  display: flex;
  flex-direction: column;
}
.rejection-reason {
  width: 250px;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
